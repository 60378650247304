<template>
  <div class="mx-2 mb-2">
    <b-row>

      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">
          {{ $t('pagination.showing') }}
          {{ query.limit * (query.page - 1) + 1 }}
          {{ $t('pagination.to') }}
          {{ query.limit * query.page > total ? total : query.limit * query.page }}
          {{ $t('pagination.of') }}
          {{ total }}
          {{ $t('pagination.entries') }}
        </span>
      </b-col>

      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <b-pagination
          v-if="total > query.limit"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.limit"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>
<script>
import { BCol, BPagination, BRow } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  props: {
    query: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
}
</script>
